import { z } from 'zod';

const nameRegex = /^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,0-9]{1,30}$/;

const trimString = (u: unknown) => (typeof u === 'string' ? u.trim() : u);

export const name = (message = 'This field is empty or invalid.') =>
  z.preprocess(
    trimString,
    z
      .string()
      .max(30)
      .refine((val) => nameRegex.test(val), { message })
  );

export const required = (message = 'This field is required.', max = 100) =>
  z.preprocess(
    trimString,
    z
      .string()
      .max(max, {
        message: `The maximum legth of this field is ${max} characters.`,
      })
      .nonempty({ message })
  );

export const emailAddress = (message = 'Please enter valid email address') =>
  z.string().email({ message });

export const trueOnly = (message = 'Invalid') =>
  z.boolean().refine((val) => val === true, {
    message,
  });

const emailRegex = new RegExp(
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
);
export const validateEmail = (email: string) => emailRegex.test(email);

export { z };
